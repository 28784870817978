/* eslint-disable eqeqeq */
/* eslint-disable jsx-quotes */
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Device from './Device';
import Footer from './Footer';
import Header from './Header';

const CANONICAL_DOMAIN = 'https://hanagold.vn';
function Layout({ children, webViewMobile }) {
  const [offset, setOffset] = useState(0);
  const [marginTop, setMarginTop] = useState(0);
  const router = useRouter();
  const query = router?.query || {};

  const isQueryApp = query?.viewapp ? true : false;
  const isViewApp = webViewMobile || isQueryApp;
  const refCode = query?.ref || '';
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadInit();
    }

    window.onscroll = () => {
      const scrollTop = window.pageYOffset;
      setOffset(scrollTop);
      if (scrollTop >= 200) {
        if (
          [
            '/dai-su-kinh-doanh-vang',
            '/app-mua-vang-online',
            '/dong-vang-cong-nghe-hanagold',
            '/doi-tac',
            '/giay-phep-giay-chung-nhan-hanagold',
          ].includes(router.pathname)
        ) {
          setMarginTop(0);
        }
      } else {
        setMarginTop(0);
      }
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('lang', router.locale);
  }, [router.locale]);
  const check_route =
    router?.query?.embed == '1'
      ? true
      : router?.query?.embed == '0'
      ? false
      : false;

  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0
      ? router.asPath.indexOf('?')
      : router.asPath.length,
    router.asPath.indexOf('#') > 0
      ? router.asPath.indexOf('#')
      : router.asPath.length,
  ]);
  const canonicalURL =
    CANONICAL_DOMAIN + router.asPath.substring(0, _pathSliceLength);

  const loadInit = async () => {
    if (refCode) {
      localStorage.setItem('refs', refCode);
    }
    if (window.wow) {
      window.wow.init();
      setTimeout(() => {
        window.wow.sync();
      }, 50);
    } else {
      const WOW = await import('../../node_modules/wowjs/dist/wow.js');
      const wow = new WOW.WOW({
        boxClass: 'wow', // default
        animateClass: 'animated', // default
        offset: 0, // default
        mobile: false,
        live: false, // default
      });
      wow.init();
      window.wow = wow;
    }
  };

  const onClickScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Head>
        <meta name="format-detection" content="telephone=no" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="HanaGold" />
        <meta name="geo.region" content="VN" />
        <meta name="geo.placename" content="Hồ Chí Minh" />
        <meta name="geo.position" content="10.7500358;106.624365" />
        <meta name="ICBM" content="10.7500358, 106.624365" />

        <link rel="canonical" href={canonicalURL} />
        {/* <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
          integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm"
          crossOrigin="anonymous"
        ></link> */}
        <meta charSet="utf-8" />
        <link
          rel="icon"
          type="image/png"
          href="/assets/img/common/favicon.png"
        ></link>
        <script
          dangerouslySetInnerHTML={{
            __html: check_route
              ? ``
              : `
              window.VNLP = {
                botId: '650a46af54e50a6d1b635f3d',
                options: {
                  theme: {
                    chatbotName: '\bSena',
                    chatbotDescription: 'Trợ lý ảo',
                    chatbotMessageColor: {
                      textColor: '#777e90',
                      backgroundColor: '#ffffff',
                    },
                    headingColor: '#ff7500',
                    userMessageColor: {
                      textColor: '#ffffff',
                      backgroundColor: '#353945',
                    },
                    chatIcon:
                      'https://storage.googleapis.com/system-prod/b8e3483a-81f2-48b7-83ec-570f2acba1f8.png',
                  },
                },
              }
              `,
          }}
        />
        <Device>
          {({ isMobile }) => (
            <>
              {isMobile && (
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
              )}
              {!isMobile && (
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
              )}
            </>
          )}
        </Device>
      </Head>
      <div className="idhwjandbasbasb">
        {(router.pathname !== '/priority-table' && router.pathname !== '/vip-fees-table') && !isViewApp && !check_route ? <Header offset={offset} /> : null}
        <div className="content-container" style={{ marginTop: marginTop }}>
          {children}
        </div>
        <Device>
          {({ isMobile }) =>
            !isViewApp && !check_route ? (
              (router.pathname !== '/priority-table' && router.pathname !== '/vip-fees-table') && <Footer isMobile={isMobile} />
            ) : null
          }
        </Device>
        {offset > 500 ? (
          <button
            onClick={onClickScroll}
            title="Back to top"
            className="scroll-top"
          >
            <span className="arrow-up glyphicon glyphicon-chevron-up">
              {' '}
              <img src="/assets/img/186407_arrow_up_icon.svg" alt="btn" />{' '}
            </span>
          </button>
        ) : (
          false
        )}
      </div>
    </>
  );
}
export default Layout;
Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {
  children: null,
};
